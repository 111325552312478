import React from 'react';

export default function NotFound() : JSX.Element {
  return (
    <div className="notFoundContainer">
      <h1 className="display-3">Hoppala!</h1>
      <p className="lead">
        Es scheint so, als ob Sie auf eine Seite gestossen sind, die nicht existiert...
        Bitte navigieren sie im Menü auf eine andere Seite.
        <br />
        Wenn es sich um ein Fehlverhalten
        unserer Webseite handelt,
        wären wir froh wenn Sie uns darüber informieren könnten! Vielen Dank!
      </p>
    </div>
  );
}
