import React from 'react';
import {
  Button, Grid, List, ListItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CustomMenu from './utility/CustomMenu';

const PREFIX = 'Navigation';

const classes = {
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  button: `${PREFIX}-button`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.list}`]: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    backgroundColor: '#fbd286',
  },
  [`& .${classes.listItem}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: 'auto',
    fontSize: '23px',
    padding: '5px 10px 5px 10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      padding: '5px',
    },
  },
  [`& .${classes.button}`]: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    fontSize: 'inherit',
  },
}));

function CustomListItem(props: { title: string, url?: string, hardLink?: string }) {
  const {
    title,
    url,
    hardLink,
  } = props;

  let createButton = (buttonTitle: string) => (
    <Button className={classes.button}>{buttonTitle}</Button>
  );

  if (url) {
    createButton = function createByUrl(buttonTitle: string) {
      return (
        <Button
          className={classes.button}
          component={Link}
          to={url}
        >
          {buttonTitle}
        </Button>
      );
    };
  } else if (hardLink) {
    createButton = function createWithoutUrl(buttonTitle: string) {
      return (
        <Button
          className={classes.button}
          href={hardLink}
          target="_blanc"
        >
          {buttonTitle}
        </Button>
      );
    };
  }

  return (
    <ListItem className={classes.listItem}>
      {createButton(title)}
    </ListItem>
  );
}

export default function Navigation() : JSX.Element {
  return (
    <Root>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <List className={classes.list}>
            <CustomListItem title="Home" url="/" />
            <CustomListItem title="Abteilung" url="/abteilung" />
            <ListItem className={classes.listItem}>
              <CustomMenu
                menuName="Stufen"
                items={[
                  { name: 'Übersicht', url: '/stufen', strong: true },
                  { name: 'Wölfli', url: '/stufen/woelfli' },
                  { name: 'Pfadi', url: '/stufen/pfadi' },
                  { name: 'Pio', url: '/stufen/pio' },
                  { name: 'PTA', url: '/stufen/pta' },
                ]}
              />
            </ListItem>
            <CustomListItem title="Photos" url={`/photos/${new Date().getFullYear()}`} />
            <ListItem className={classes.listItem}>
              <CustomMenu
                menuName="Infos"
                items={[
                  { name: 'Bekleidung', url: '/bekleidung' },
                  { name: 'Pfadiheim', url: '/pfadiheim' },
                ]}
              />
            </ListItem>
            <CustomListItem title="APV" url="/apv" />
            <CustomListItem title="Archiv" hardLink="http://bischofstein.ch/inhalt/bischofstein/archiv.shtml" />
          </List>
        </Grid>
        <Grid item xs={12} lg={4}>
          <List className={classes.list} style={{ justifyContent: 'flex-end' }}>
            <CustomListItem title="Intern" url="/intern" />
            <CustomListItem title="Links" url="/links" />
            <CustomListItem title="Team" url="/team" />
            <CustomListItem title="Kontakt" url="/kontakt" />
          </List>
        </Grid>
      </Grid>
    </Root>
  );
}

CustomListItem.defaultProps = {
  url: '',
  hardLink: '',
};
