import React, { useContext } from 'react';

import ConfigContext from '../../context/ConfigContext';
import StufenTemplate from './StufenTemplate';

function Pta():JSX.Element {
  const { staticUrls } = useContext(ConfigContext);

  const buttons = [{
    label: 'Antretensplan',
    type: 'pdf',
    fetcher: (): Promise<string> => {
      const link = `/getGenericPdf/${staticUrls?.pta.antreten}`;

      return fetch(link)
        .then((data) => data.blob())
        .then((data) => {
          const file = new Blob([data], {
            type: 'application/pdf',
          });
          return URL.createObjectURL(file);
        });
    },
  }];

  const contact = (
    <>
      <div>
        Lea Fricker / Felidea
        <br />
        Email: felidea@bischofstein.ch
      </div>
      <div>
        Marian Kretzer / Faramir
        <br />
        Email: faramir@bischofstein.ch
      </div>
    </>
  );

  return (
    <StufenTemplate
      isLoading={!!staticUrls}
      buttons={buttons}
      title="PTA-Stamm Dorneck"
      titleImage={staticUrls?.pta.front}
      contact={contact}
    >
      <p>
        Seit 1957 nehmen Kinder, Jugendliche und auch Erwachsene mit
        Assistenzbedarf an den all zwei wöchig stattfindenden Aktivitäten teil.
        Der jeweilige Treffpunkt wird per E-Mail oder Brief
        mitgeben und steht auch auf unserer Homepage.
        <br />
        <br />
        Wir sind eine bunte Gruppe von Menschen im Alter zwischen 10 und 37 Jahren.
        Das Programm versuchen wir so abzustimmen,
        dass alle etwas mitnehmen können. Wir sind bei jedem Wetter draussen,
        spielen Spiele oder machen Feuer, manchmal
        schnitzen oder basteln wir, manchmal singen wir zusammen oder verfolgen eine Schnitzeljagt.
        Meistens sind unsere Aktivitäten thematisch eingekleidet.
        <br />
        <br />
        Die PTA sind wie die Wölfe und die Pfadi ein Teil der Abteilung Bischofstein.
        Es gibt gemeinsame Anlässe, bei denen die ganze Abteilung
        zusammen ist (z.B. Pfingstlager oder Niggi-Näggi-Weekend), auch
        gibt es immer wieder gemeinsame Aktivitäten.
        <br />
        <br />
        Wenn Du Interesse hast, komm doch einfach mal vorbei, wir freuen uns.
      </p>
    </StufenTemplate>
  );
}

export default Pta;
