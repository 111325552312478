import React, {
  useEffect,
  useReducer,
  useState,
} from 'react';

import { styled } from '@mui/material/styles';
import PhotoSlider from '../utility/PhotoSlider';

import imgParser, {
  DispatcherFunction,
  FetchedFile,
} from '../helper-functions/image-parser';

import { PhotoFile, PhotoMapping } from '../utility/Models';
import FadeInContainer from '../utility/FadeInContainer';

const Prefix = 'Pfadiheim';

const classes = {
  floatLeft: `${Prefix}-float`,
};

const Root = styled(FadeInContainer)({
  [`& .${classes.floatLeft}`]: {
    floatLeft: 'left',
  },
});

export interface PfadiheimImages {
  isPhotoSliderOpen: boolean,
  activePhoto: number
}

const init = () => ({});

const reducer = (state: PhotoMapping, update: FetchedFile) => {
  const file = update.data as PhotoFile;

  return {
    ...state,
    [file.name]: file,
  };
};

function Pfadiheim(): JSX.Element {
  const [{
    isPhotoSliderOpen,
    activePhoto,
  }, setState] = useState<PfadiheimImages>({
    isPhotoSliderOpen: false,
    activePhoto: 0,
  });

  const [pfadiheimImgs, dispatch] = useReducer<DispatcherFunction, PhotoMapping>(reducer, {}, init);

  const styles = {
    body: {
      display: 'flex',
      margin: '10px',
      justifyContent: 'space-between',
    },
    description: {
      width: '50%',
    },
    image: {
      width: '45%',
    },
    imageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    singleImage: {
      margin: '2px',
    },
  };

  useEffect(() => {
    const link = '/getPhotos/Heim';
    fetch(link)
      .then((data) => imgParser(data, dispatch, true))
      .catch((err) => console.error(err.message));
  }, [dispatch]);

  const openModal = (photoIndex = 0) => {
    setState((prevState) => ({
      ...prevState,
      activePhoto: photoIndex,
      isPhotoSliderOpen: true,
    }));
  };

  const keyHandler = (key: string, index: number) => {
    if (key === 'Enter') { openModal(index); }
  };

  const getImages = () => {
    const allPhotos = Object.keys(pfadiheimImgs);

    if (!allPhotos.length) return null;

    return allPhotos.map((photoName, index) => {
      const img = pfadiheimImgs[photoName];

      return (
        <div
          onClick={() => openModal(index)}
          onKeyUp={(e) => keyHandler(e.code, index)}
          role="button"
          tabIndex={0}
          key={img.name}
          style={styles.singleImage}
        >
          <img
            src={img.data}
            height="70px"
            alt="Problem here"
          />
        </div>
      );
    });
  };

  const closePhotoSlider = () => {
    setState((prevState) => ({
      ...prevState,
      isPhotoSliderOpen: false,
    }));
  };

  return (
    <Root trigger flexDirection="column">
      <PhotoSlider
        isOpen={isPhotoSliderOpen}
        onClose={closePhotoSlider}
        photos={pfadiheimImgs}
        initialIndex={activePhoto}
      />

      <h1 className="no-margin-bottom border-bottom-black">Unser Pfadiheim</h1>
      <div style={styles.body}>
        <div style={styles.description}>
          <div className="margin-top">
            Unser Pfadiheim gehört dem APV und wird uns für den Pfadibetrieb zur Verfügung
            gestellt. Hier treffen sich die Leitenden zum Planen der Aktivitäten; ausserdem
            finden manche Anlässe im Pfadiheim statt.
            <br />
            <br />
            Durch die zentrale Lage beim St. Jakob-Park profitieren wir von guter Erreichbarkeit
            mit dem Öffentlichen Verkehr und hervorragende Einkaufsmöglichkeiten.

          </div>
          <h2 className="no-margin-bottom border-bottom-black">Bilder:</h2>
          <div style={styles.imageContainer as React.CSSProperties}>{getImages()}</div>
        </div>
        <iframe
          title="PfadiHeimBischofstein"
          src="https://www.google.com/maps/d/embed?mid=1n1IToYJXLRPxIn2j7a3OmsOW1oa5rnc0"
          width="640"
          height="380"
          style={styles.image}
        />
      </div>
    </Root>
  );
}

export default Pfadiheim;
