import React, { useContext, useState } from 'react';
import { Collapse } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ConfigContext from '../context/ConfigContext';
import FadeInContainer from '../utility/FadeInContainer';

function Bekleidung(): JSX.Element {
  const { staticUrls } = useContext(ConfigContext);

  const [isOpen, setState] = useState(false);

  const toggle = () => {
    setState(!isOpen);
  };

  const keyHandler = (key: string) => {
    if (key === 'Enter') { toggle(); }
  };

  const styles = {
    container: {
      display: 'flex',
      width: '100%',
    },
    content: {
      width: '70%',
      display: 'flex',
      flexDirection: 'column',
    },
    laedeli: {
      width: '30%',
    },
    row: {
      display: 'flex',
      heigh: '20%',
      justifyContent: 'space-around',
    },
    img: {
      height: '100%',
    },
    title: {
      display: 'flex',
      width: '100%',
    },
    h4: {
      width: '100%',
      margin: '5px',
    },
  };

  return (
    <FadeInContainer trigger={!!staticUrls}>
      <div style={styles.container}>
        <div style={styles.content as React.CSSProperties}>
          <div style={styles.title}>
            <h4 style={styles.h4} className="sectionHeader">Pfadi-Hemden</h4>
          </div>
          <div>
            Pfadis haben immer ein Hemd an. Auf dieses Hemd wird die Pfadi-Laufbahn verewigt
            in Form von Lagerdrücken, Abzeichen, Versprechen und vieles mehr.
          </div>
          {
            staticUrls
              ? (
                <div style={styles.row}>
                  <div className="imgText-bekleidung">
                    <img
                      className="bekleidung_img"
                      style={styles.img}
                      src={staticUrls.bekleidung.woelfli}
                      alt="Problem here"
                    />
                    <p>Wölfli-Hemd</p>
                  </div>
                  <div className="imgText-bekleidung">
                    <img
                      className="bekleidung_img"
                      style={styles.img}
                      src={staticUrls.bekleidung.pfadi}
                      alt="Problem here"
                    />
                    <p>Pfadi-Hemd</p>
                  </div>
                </div>
              )
              : <CircularProgress color="secondary" />
          }
          <div style={styles.title}>
            <h4 style={styles.h4} className="sectionHeader">Krawatte</h4>
            <h4 style={styles.h4} className="sectionHeader">Abzeichen</h4>
          </div>
          {
            staticUrls
              ? (
                <div style={styles.row}>
                  <div>
                    Die Krawatte repräsentiert primär die Abteilungszugehörigkeit
                    (bei uns also Pfadi Bischofstein) eines Pfadfinder.
                    Unsere Farben sind Grün und Weiss.
                    <img
                      className="bekleidung_img"
                      style={styles.img}
                      src={staticUrls.bekleidung.krawatte}
                      alt="Problem here"
                    />
                  </div>
                  <div>
                    Im Bild ist ein Beispiel einiger Abzeichen gezeigt (Hier Spezi-Abzeichen).
                    Diese können auf die Uniform genäht werden.
                    <img
                      className="bekleidung_img"
                      style={styles.img}
                      src={staticUrls.bekleidung.abzeichen}
                      alt="Problem here"
                    />
                  </div>
                </div>
              )
              : <CircularProgress color="secondary" />
          }
        </div>
        <div
          className="pfadilaedeli"
          style={styles.laedeli}
        >
          <div>
            <div
              onClick={toggle}
              onKeyUp={(e) => keyHandler(e.code)}
              role="button"
              tabIndex={0}
            >
              <h5>Pfadiladen</h5>
            </div>
            <Collapse in={isOpen}>
              In Basel gibt es einen Laden, der speziell für den Pfadibedarf eingerichtet ist.
              Dort findest du alles, was du im Pfadileben benötigst.
              Das ehrenamtliche Verkäuferteam berät dich gerne.
              {' '}
              <br />
              <b>Öffnungszeiten:</b>
              {' '}
              Dienstag und Donnerstag (ausser in den Schulferien), 18:00 - 19:00 Uhr
              <br />
              <b>Homepage: </b>
              <a href="https://www.pfadiladen.ch" target="_blanc">www.pfadiladen.ch</a>
              <br />
              <b>Kontak: </b>
              info@pfadiladen.ch
              <br />
              <b>Wo:</b>
              <br />
              <iframe
                title="pfadilaedele"
                // eslint-disable-next-line max-len
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2327.7156645585883!2d7.583838713134346!3d47.5592074876978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4791b9aed2f197fd%3A0xa5b695594db88b63!2sKellerg%C3%A4sslein%2C%204051%20Basel!5e0!3m2!1sde!2sch!4v1607264040398!5m2!1sde!2sch"
                width="100%"
                height="250"
                frameBorder="0"
                style={{
                  border: 0,
                }}
                aria-hidden="false"
              />
            </Collapse>
          </div>
        </div>
      </div>
    </FadeInContainer>
  );
}

export default Bekleidung;
