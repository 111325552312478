export default function LockScroll(enable: boolean): () => void {
  if (enable) {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    document.body.style.overflow = 'hidden';

    return (
      () => {
        document.body.style.overflow = originalStyle;
      }
    );
  }
  const noScrollStyle = window.getComputedStyle(document.body).overflow;

  document.body.style.overflow = 'visible';

  return (
    () => {
      document.body.style.overflow = noScrollStyle;
    }
  );
}
