import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './utility/Theme';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import './App_mobile900.css';
import './App_mobile450.css';
import './App_mobile0.css';

import Header from './Header';
import SwitchCom from './SwitchCom';
import Footer from './Footer';

import ConfigContext from './context/ConfigContext';

import { ConfigModel } from './utility/Models';

function App(): JSX.Element {
  const [config, setConfig] = useState<ConfigModel>({});

  function fetchConfig(): Promise<ConfigModel> {
    return Promise.all([
      fetch('/config/allFiles.json'),
      fetch('/config/allLinks.json'),
      fetch('/config/homeNews.json'),
      fetch('/config/info.json'),
      fetch('/config/staticURLs.json'),
      fetch('/config/stufen.json'),
      fetch('/config/team.json'),
      fetch('/config/verteiler.json'),
    ])
      .then((allData) => Promise.all(
        allData.map((data) => data.json().catch(() => console.error(data.url))),
      ))
      .then(([
        fileNamesMapping,
        links,
        homeNews,
        info,
        staticUrls,
        stufen,
        team,
        verteiler,
      ]) => ({
        fileNamesMapping,
        links,
        homeNews,
        info,
        staticUrls,
        stufen,
        team,
        verteiler,
      }));
  }

  useEffect(() => {
    fetchConfig()
      .then((pulledConfig) => setConfig(() => pulledConfig));
  }, []);

  return (
    <div className="App">
      <ConfigContext.Provider value={config}>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <BrowserRouter>
              <div>
                <div className="bodyWithoutFooter">
                  <Header />
                  <SwitchCom />
                </div>
                <Footer latestUpdate={
                  config.staticUrls ? config.staticUrls.latestUpdate : null
                }
                />
              </div>
            </BrowserRouter>
          </CssBaseline>
        </ThemeProvider>
      </ConfigContext.Provider>
    </div>
  );
}

export default App;
