import React, { useContext, useState } from 'react';
import ConfigContext from '../../context/ConfigContext';

import StufenTemplate from './StufenTemplate';

function Woelfli():JSX.Element {
  const { staticUrls } = useContext(ConfigContext);

  const [{ isOpen }, setState] = useState<{ isOpen: boolean, antreten: string | null }>({
    isOpen: false,
    antreten: null,
  });

  const toggle = () => {
    const link = `/getGenericPdf/${staticUrls?.woelfli.antreten}`;

    setState((prevState) => ({ ...prevState, isOpen: !isOpen }));

    fetch(link)
      .then((data) => data.blob())
      .then((data) => {
        const file = new Blob([data], {
          type: 'application/pdf',
        });
        return URL.createObjectURL(file);
      })
      .then((pdf) => setState((prevState) => ({ ...prevState, antreten: pdf })));
  };

  const buttons = [{
    label: 'Makinee',
    type: 'pdf',
    fetcher: (): Promise<string> => {
      const link = `/getGenericPdf/${staticUrls?.woelfli.antreten}`;

      return fetch(link)
        .then((data) => data.blob())
        .then((data) => {
          const file = new Blob([data], {
            type: 'application/pdf',
          });
          return URL.createObjectURL(file);
        });
    },
  }];

  const contact = (
    <div>
      Elias Steiner / Quill
      <br />
      Email: quill@bischofstein.ch
    </div>
  );

  return (
    <StufenTemplate
      isLoading={!!staticUrls}
      buttons={buttons}
      title="Wölflistamm Seeonee"
      titleImage={staticUrls?.woelfli.front}
      contact={contact}
    >
      <p>
        Mädchen und Buben von 6-10 Jahren gehören zu den Wölfen.
        Sie werden von einem erfahrenen Leitungsteam betreut.
        Ihre Aktivitäten finden an den Samstagnachmittagen statt.
        Treffpunkt ist jeweils an einer Tram- oder Bushaltestelle in der Region.
        Viele der Nachmittage sind in eine Geschichte eingekleidet.
        Die Geschichte und der Aufenthalt in der Natur laden ein, in eine
        Phantasiewelt einzutauchen und Neues zu entdecken.
        Beim gemeinsamen Spielen, Herumtollen, Singen und Basteln lernen die Wölfli die
        Regeln des Zusammenlebens in der Gemeinschaft kennen.
        <br />
        <br />
        Über Pfingsten fährt jeweils die ganze Abteilung in das Pfingstlager.
        Dazu führen die Wölfe - meist in der ersten Herbstferienwoche
        in einem Haus - ein einwöchiges Lager durch
        (genaue Angaben siehe aktuelles Jahresprogramm!).
        <br />
        <br />
        Wo wir uns in der nächsten Zeit treffen, kannst du dem
        <span
          className="link-to-doc"
          onClick={toggle}
          onKeyUp={toggle}
          role="button"
          tabIndex={0}
        >
          Makinee
        </span>
        entnehmen.
        <br />
        <br />
        Wir freuen uns auf deinen Besuch!
        <br />
        <br />
        Weitere Informationen findest du im
        <a href="http://bischofstein.ch/inhalt/stufen/pfadiprofil.pdf" target="_blanc">Pfadiprofil</a>
        der Pfadibewegung Schweiz!
      </p>
    </StufenTemplate>
  );
}

export default Woelfli;
