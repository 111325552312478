import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './content/Home';
import Abteilung from './content/Abteilung';
import Apv from './content/Apv';
import Info from './content/Info';
import Bekleidung from './content/Bekleidung';
import Pfadiheim from './content/Pfadiheim';
import Intern from './content/Intern';
import Team from './content/Team';
import Kontakt from './content/Kontakt';
import Links from './content/Links';
import Photos from './content/Photos';
import Stufen from './content/stufen/Stufen';
import Agb from './content/Agb';
import Impressum from './content/Impressum';
import Pfadi from './content/stufen/Pfadi';
import Woelfli from './content/stufen/Woelfli';
import Pta from './content/stufen/Pta';
import Pio from './content/stufen/Pio';
import NotFound from './content/NotFound';

function SwitchCom(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/" render={() => <Home />} />
      <Route path="/info" render={() => <Info />} />
      <Route path="/intern" render={() => <Intern />} />
      <Route path="/team" render={() => <Team />} />
      <Route path="/kontakt" render={() => <Kontakt />} />
      <Route path="/links" render={() => <Links />} />
      <Route path="/stufen" exact render={() => <Stufen />} />
      <Route path="/impressum" component={Impressum} />
      <Route path="/agb" component={Agb} />
      <Route path="/photos" render={({ match }) => <Photos match={match} />} />
      <Route path="/stufen/pfadi" render={() => <Pfadi />} />
      <Route path="/stufen/woelfli" render={() => <Woelfli />} />
      <Route path="/stufen/pta" render={() => <Pta />} />
      <Route path="/stufen/pio" render={() => <Pio />} />
      <Route path="/apv" render={() => <Apv />} />
      <Route path="/bekleidung" render={() => <Bekleidung />} />
      <Route path="/pfadiheim" render={() => <Pfadiheim />} />
      <Route path="/abteilung" render={() => <Abteilung />} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default SwitchCom;
