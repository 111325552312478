import React, { useContext } from 'react';

import ConfigContext from '../context/ConfigContext';

import StufenTemplate from './stufen/StufenTemplate';

function Apv(): JSX.Element {
  const { staticUrls } = useContext(ConfigContext);

  const buttons = [{
    label: 'Bankverbindung',
    source: staticUrls?.apv.bank,
  }];

  const contact = (
    <div>
      <p>
        Kontakt:
      </p>
      <p>
        Jürg Käser / Morchle
        <br />
        Email: morchle@bischofstein.ch
      </p>
    </div>
  );

  return (
    <StufenTemplate
      isLoading={!!staticUrls}
      buttons={buttons}
      title="Altpfadfinderverein (APV)"
      titleImage={staticUrls?.apv.front}
      contact={contact}
    >
      <p>
        Der APV Bischofstein ist ein Verein, welchem ehemalige
        Bischofsteiner/innen angehören. Er hat primär einen geselligen Zweck:
        Sich wiederzusehen, alte Erinnerungen aufleben zu lassen, den Kontakt
        zu erhalten oder zu &quot;längst verschollenen&quot; Kameraden
        wieder zu ermöglichen. Die Aktivitäten umfassen
        die Generalversammlung (Ende Januar) und den Bummel.
        <br />
        <br />
        Weiter steht der APV der Abteilung zur Seite: Aus Mitgliedern
        des APV rekrutieren sich in der Regel der Abteilungs-Obmann,
        der Kassier und die Revisoren. Dem APV gehört auch das Pfadiheim, welches der
        Abteilung zum Gebrauch zur Verfügung steht.
        Schlussendlich unterstützt der APV die Leiterausbildung der
        Abteilung mit einem nahmhaften Zuschuss.
      </p>
    </StufenTemplate>
  );
}

export default Apv;
