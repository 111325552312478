import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import SideDrawer from './SideNavigation';
import Navigation from './Navigation';

const PREFIX = 'Header';

const classes = {
  logo: `${PREFIX}-logo`,
  logoPos: `${PREFIX}-logoPos`,
  title: `${PREFIX}-title`,
  titleBefore: `${PREFIX}-titleBefore`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.logo}`]: {
    maxHeight: 100,
    margin: 8,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 100,
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 70,
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 50,
    },
  },
  [`& .${classes.logoPos}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  [`& .${classes.title}`]: {
    transition: '2s',
    [theme.breakpoints.down('lg')]: {
      fontSize: '5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
    },
  },
  [`& .${classes.titleBefore}`]: {
    transform: 'translate(150px, 0)',
    opacity: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: '5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
    },
  },
}));

export default function Header() : JSX.Element {
  const [slide, setSlide] = useState(false);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Root>
      <Grid container>
        <Grid item xs={2} lg={1} className={classes.logoPos}>
          <Link to="/">
            <img
              src="/logo.png"
              alt="Bischofstein Logo"
              onLoad={() => setSlide(true)}
              className={classes.logo}
            />
          </Link>
        </Grid>
        <Grid item xs={10} lg={11} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h1" className={slide ? classes.title : classes.titleBefore}>
            Pfadi Bischofstein
          </Typography>
        </Grid>
      </Grid>
      {isMobileScreen ? <SideDrawer /> : <Navigation />}
    </Root>
  );
}
