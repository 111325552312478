import React, {
  useCallback, useEffect, useReducer, useState,
} from 'react';

import { styled } from '@mui/material/styles';
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import PhotoSlider from './PhotoSlider';

import imgParserPipeline, {
  FetchedFile,
  ParsedResult,
} from '../helper-functions/image-parser';

import {
  EventPhotosProps,
  EventPhotosState,
  ModalUpdate,
  PdfFile,
  PhotoFile,
  PhotoMapping,
} from './Models';

import Modal from './GenericModal';

const Prefix = 'EventPhotos';

const classes = {
  floatLeft: `${Prefix}-float`,
  spinner: `${Prefix}-spinner`,
};

const Root = styled('div')({
  [`& .${classes.floatLeft}`]: {
    floatLeft: 'left',
  },

  [`& .${classes.spinner}`]: {
    width: '300px',
    height: '200px',
    transition: '1000ms',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
  },
});

interface MediaState {
  photo: PhotoMapping,
  pdf: PdfFile[],
}

const init = () => ({
  photo: {},
  pdf: [],
});

const reducer = (state: MediaState, update: FetchedFile) => {
  const {
    photo,
    pdf,
  } = state;

  const { data, type } = update;

  switch (type) {
    case 'img': {
      const file = data as PhotoFile;

      const newPhotos = {
        ...photo,
        [file.name]: file,
      };

      return {
        ...state,
        photo: newPhotos,
      };
    }
    case 'pdf': {
      const pdfFile = data as PdfFile;

      const newPdf = [
        ...pdf,
        pdfFile,
      ];

      return {
        ...state,
        pdf: newPdf,
      };
    }
    case 'video':
    default: return state;
  }
};

function EventPhotos(props: EventPhotosProps):JSX.Element {
  const {
    link,
    path,
    files,
  } = props;

  const [{
    modal,
    activeItem,
    pdfData,
  }, setState] = useState<EventPhotosState>({
    modal: '',
    activeItem: 0,
    pdfData: '',
  });

  const [{
    photo,
    pdf,
  }, dispatch] = useReducer<(state: MediaState, file: FetchedFile) => MediaState,
  MediaState
  >(reducer, {
      photo: {},
      pdf: [],
    }, init);

  const [activeTab, setActiveTab] = React.useState('1');

  const styles = {
    photoContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    row: {
      flex: 'display',
    },
    col: {
      margin: '2px',
    },
    tabs: {
      margin: '5px',
    },
  };

  const mapPhotos = (allPhotos: PhotoFile[]) => allPhotos.reduce((final, file) => ({
    ...final,
    [file.name]: file,
  }), {});

  const updateData = useCallback((currentData: ParsedResult) => {
    setState((prevState) => ({
      ...prevState,
      mov: currentData.mov,
      photo: mapPhotos(currentData.img),
      pdf: currentData.pdf,
    }));
  }, []);

  useEffect(() => {
    fetch(link, {
      // 'Transfer-Encoding': 'chunked',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((chunk) => imgParserPipeline(
        chunk,
        dispatch,
        true,
      ))
      .catch((err) => {
        console.error(err);
      });
  }, [link, updateData]);

  const getPdf = (pdfName: string) => {
    const name = pdfName.substring(0, pdfName.length - 4);

    const pdfLink = `/getGenericPdf/${name}/${path}`;

    return fetch(pdfLink)
      .then((pdfRaw) => pdfRaw.blob())
      .then((pdfParsed) => {
        const file = new Blob([pdfParsed], {
          type: 'application/pdf',
        });

        return URL.createObjectURL(file);
      });
  };

  const getSlides = (currentPhotos: PhotoMapping, initPhoto: string) => {
    const allPhotos = Object.keys(currentPhotos);
    const initSlides = allPhotos.map((ph) => currentPhotos[ph]);
    const initialIndex = initSlides.findIndex((slide) => slide.name === initPhoto);

    return {
      initSlides,
      initialIndex,
    };
  };

  const toggleModal = async (newModal: string, seletedItem?: string) => {
    const update:ModalUpdate = {
      modal: newModal,
    };

    if (newModal === 'pdf' && seletedItem) update.pdfData = await getPdf(seletedItem);
    if (newModal === 'photogallery' && seletedItem) {
      const { initSlides, initialIndex } = getSlides(photo, seletedItem);
      update.activeItem = initialIndex;
      update.slides = initSlides;
    }

    setState((prevState) => ({ ...prevState, ...update }));
  };

  const buttonHandler = (key: string, photoName: string) => {
    if (key === 'Enter') {
      toggleModal('photogallery', photoName);
    }
  };

  const loadedPhotoDisplay = (singlePhoto: PhotoFile) => (
    <div
      onClick={() => toggleModal('photogallery', singlePhoto.name)}
      onKeyUp={(e) => buttonHandler(e.code, singlePhoto.name)}
      role="button"
      tabIndex={0}
      className={classes.floatLeft}
      key={singlePhoto.name}
    >
      <img
        key={singlePhoto.name}
        className="photos"
        src={singlePhoto.data}
        alt="problem here"
        height="200px"
      />
    </div>
  );

  const stillLoadingPhoto = (photoName: string) => (
    <div
      className={classes.floatLeft}
      key={photoName}
    >
      <div className={classes.spinner}>
        <CircularProgress color="secondary" />
      </div>
    </div>
  );

  const displayPhotos = () => files.map((file: string) => {
    const singlePhoto = photo[file];

    if (!singlePhoto) {
      return stillLoadingPhoto(file);
    }

    return loadedPhotoDisplay(singlePhoto);
  });

  const displayPdfMovie = () => {
    const chunkedData = [];
    for (let i = 0; i < pdf.length; i += 3) {
      chunkedData.push(pdf.slice(i, i + 3));
    }

    const src = '/pdf.jpg';

    return chunkedData.map((chunk) => (
      <div style={styles.row as React.CSSProperties} key={chunk[0].name}>
        {
          chunk.map((currentPdf) => (
            <div style={styles.col} key={currentPdf.name}>
              <div
                onClick={() => toggleModal('pdf', currentPdf.name)}
                onKeyUp={() => toggleModal('pdf', currentPdf.name)}
                role="button"
                tabIndex={-1}
              >
                <img
                  className="photos"
                  src={src}
                  alt="problem here"
                  height="200px"
                />
                {
                  currentPdf.name !== null && currentPdf.name !== undefined
                    ? <div>{currentPdf.name}</div>
                    : null
                }
              </div>
            </div>
          ))
        }
      </div>
    ));
  };

  const downloadImg = () => {
    const a = document.createElement('a');
    a.download = 'test.jpg';
    a.click();
  };

  const renderModal = () => {
    switch (modal) {
      case 'photogallery': {
        return (
          <PhotoSlider
            isOpen={modal === 'photogallery'}
            onClose={() => toggleModal('')}
            photos={photo}
            initialIndex={activeItem}
            downloadCurrentImage={() => downloadImg()}
          />
        );
      }
      case 'pdf': {
        return (
          <Modal
            closeModal={() => toggleModal('')}
            size="lg"
            isOpen
          >
            <object data={pdfData} type="application/pdf" height="100%" width="100%">
              Error loading
            </object>
          </Modal>
        );
      }
      default: return null;
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Root>
      <TabContext value={activeTab}>
        <Box style={styles.tabs} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            aria-label="basic tabs example"
            textColor="secondary"
            onChange={handleChange}
          >
            <Tab value="1" label="Fotos" disabled={Object.keys(photo).length === 0} />
            <Tab value="2" label="Pdf" disabled={pdf.length === 0} />
          </Tabs>
        </Box>
        <TabPanel value="1">
          <div style={styles.photoContainer as React.CSSProperties}>
            {displayPhotos()}
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div style={styles.photoContainer as React.CSSProperties}>
            {displayPdfMovie()}
          </div>
        </TabPanel>
      </TabContext>
      {
        renderModal()
      }
    </Root>
  );
}

export default EventPhotos;
