import React, { useEffect, useState } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';

import { Collapse } from '@mui/material';
import LoadingComp from '../utility/LoadingComp';
import CustomBreadcrumb from '../utility/CustomBreadcrumb';
import { PhotosModel, PhotosStateModel } from '../utility/Models';
import FadeInContainer from '../utility/FadeInContainer';

function Photos(props: PhotosModel): JSX.Element {
  const [{
    showArchive,
    showOlderYears,
    allYears,
    archiveYears,
    loading,
    error,
  }, setState] = useState<PhotosStateModel>({
    showArchive: false,
    showOlderYears: false,
    allYears: [],
    archiveYears: [],
    loading: true,
    error: null,
  });

  const {
    match: {
      url,
      path,
    },
  } = props;

  const styles = {
    nav: {
      width: '20%',
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      width: '80%',
    },
    menuCollapsable: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const sortYears = (a: string, b: string) => {
    if (Number.isNaN(a) || Number.isNaN(b)) return -1;
    return parseFloat(b) - parseFloat(a);
  };

  useEffect(() => {
    fetch('/getPhotoYears')
      .then((data) => data.json())
      .then((fetchedYears) => {
        if (fetchedYears.error) {
          setState((prevState) => ({
            ...prevState,
            allYears: [],
          }));
          return;
        }

        setState((prevState) => ({
          ...prevState,
          allYears: fetchedYears.years.sort(sortYears),
          archiveYears: fetchedYears.archiveYears.sort(sortYears),
          loading: false,
        }));
      });
  }, []);

  const toggleOlderYears = () => {
    setState((prevState) => ({ ...prevState, showOlderYears: !showOlderYears }));
  };

  const toggleArchive = () => {
    setState((prevState) => ({ ...prevState, showArchive: !showArchive }));
  };

  const getAllYears = () => {
    if (error) return error;
    if (allYears.length === 0) return null;

    const allYearsNumbers = allYears.filter((year: string) => !Number.isNaN(year));
    const allYearsNotNumbers = allYears.filter((year: string) => Number.isNaN(year));

    const onlyFiveYears = allYearsNumbers.slice(0, 5);
    const restYears = allYearsNumbers.slice(6, allYears.length);

    const recentYears = onlyFiveYears.map((year: string) => (
      <NavLink key={`navLink${year}`} className="yearButton" to={`${url}/${year}`}>
        {year}
      </NavLink>
    ));

    const olderYears = restYears.map((year: string) => (
      <NavLink key={`navLink${year}`} className="yearButton collapseItem" to={`${url}/${year}`}>
        <span className="glyphicon glyphicon-chevron-right listIcon" />
        {year}
      </NavLink>
    ));

    const archiveLinks = archiveYears.map((year) => (
      <NavLink key={`navLink${year}`} className="yearButton collapseItem" to={`${url}/a_${year}`}>
        <span className="glyphicon glyphicon-chevron-right listIcon" />
        {year}
      </NavLink>
    ));

    const keyHandler = (key: string, func: () => void) => {
      if (key === 'Enter') { func(); }
    };

    const olderYearsTab = (
      <div key="olderYearsParent" className="collapseContainer">
        <div
          className="yearButton"
          onClick={toggleOlderYears}
          onKeyUp={(e) => keyHandler(e.code, toggleOlderYears)}
          role="button"
          tabIndex={0}
        >
          <span className={
            showArchive ? 'glyphicon glyphicon-chevron-down listIcon' : 'glyphicon glyphicon-chevron-right listIcon'
          }
          />
          Ältere Jahre
        </div>
        <Collapse
          className="photoNav"
          in={showOlderYears}
        >
          <div
            style={styles.menuCollapsable as React.CSSProperties}
          >
            {olderYears.map((item) => item)}
          </div>
        </Collapse>
      </div>
    );

    const archiveTab = (
      <div key="archiveParent" className="collapseContainer">
        <div
          className="yearButton"
          onClick={toggleArchive}
          onKeyUp={(e) => keyHandler(e.code, toggleArchive)}
          role="button"
          tabIndex={-1}
        >
          <span className={
            showArchive ? 'glyphicon glyphicon-chevron-down listIcon' : 'glyphicon glyphicon-chevron-right listIcon'
          }
          />
          Archiv
        </div>
        <Collapse
          className="photoNav"
          in={showArchive}
        >
          {archiveLinks.map((item) => item)}
        </Collapse>
      </div>
    );

    const various = allYearsNotNumbers.map((year: string) => (
      <NavLink key={`navLink${year}`} className="yearButton" to={`${url}/${year}`}>
        {year}
      </NavLink>
    ));

    return [recentYears, olderYearsTab, archiveTab, various];
  };

  const getLoadingComponent = (
    match: {
      match: {
        params:
        {
          year: string,
          event: string,
          category: string
        }
      }
    },
  ) => {
    const {
      match: {
        params: {
          year,
          event,
          category,
        },
      },
    } = match;

    return (
      <div>
        <CustomBreadcrumb
          year={year}
          event={event}
          category={category}
        />
        <LoadingComp
          year={year}
          event={event}
          category={category}
        />
      </div>
    );
  };

  return (
    <FadeInContainer trigger={!loading}>
      <div style={styles.nav as React.CSSProperties}>
        {getAllYears()}
      </div>
      <div style={styles.body}>
        <Switch>
          <Route exact path={`${path}/:year`} component={getLoadingComponent} />
          <Route exact path={`${path}/:year/:event`} component={getLoadingComponent} />
          <Route path={`${path}/:year/:event/:category`} component={getLoadingComponent} />
          <Route exact path={path} render={() => (<div>Nothing selected</div>)} />
        </Switch>
      </div>
    </FadeInContainer>
  );
}

export default Photos;
