import React from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

interface Props {
  latestUpdate: string | null
}

function Footer(props: Props) {
  const {
    latestUpdate,
  } = props;

  const updateDate = latestUpdate ? new Date(latestUpdate) : new Date();
  const stringifiedDate = `${updateDate.getDate()}.${updateDate.getMonth() + 1}.${updateDate.getFullYear()}`;

  return (
    <div className="footer">
      <div>
        Letztes Update:
        {' '}
        {stringifiedDate}
      </div>

      <div className="ml-auto">
        <a
          href="https://www.facebook.com/Pfadi-Bischofstein-Basel-122499744441759/"
          target="_blanc"
        >
          <i
            className="fa fa-facebook-square fa-lg"
          />
        </a>
        <Button className="footerItem">
          <Link to="/impressum" className="nav-title">Impressum</Link>
        </Button>
        <Button className="footerItem">
          <Link to="/agb" className="nav-title">AGB</Link>
        </Button>
      </div>
    </div>
  );
}

export default Footer;
