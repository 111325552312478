import React, { useState, useContext } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { CircularProgress, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GenericModal from './GenericModal';

import ConfigContext from '../context/ConfigContext';

import { AntretenBannerState } from './Models';

const useStyles = makeStyles({
  banner: {
    width: '50%',
    margin: 'auto',
    marginTop: '10px',
  },
  bannerItems: {
    margin: 'auto',
  },
});

function AntretenBanner(): JSX.Element {
  const classes = useStyles();

  const { staticUrls } = useContext(ConfigContext);
  const [{ isOpen, antreten }, setState] = useState<AntretenBannerState>({
    isOpen: false,
    antreten: null,
  });

  const briefe : string[] = [
    'Pfadi',
    'Wölfe',
    'PTA',
  ];

  const openPDF = (stufe: string) => {
    let targetFile = null;
    switch (stufe) {
      case briefe[0]: {
        targetFile = staticUrls?.pfadi.antreten;
        break;
      }
      case briefe[1]: {
        targetFile = staticUrls?.woelfli.antreten;
        break;
      }
      case briefe[2]: {
        targetFile = staticUrls?.pta.antreten;
        break;
      }
      default: { console.info('unknown stufe: ', stufe); }
    }

    if (!targetFile) return;

    const link = `/getGenericPdf/${targetFile}`;

    setState((prevState) => ({ ...prevState, isOpen: !isOpen }));

    fetch(link)
      .then((data) => data.blob())
      .then((data) => {
        const file = new Blob([data], {
          type: 'application/pdf',
        });
        return URL.createObjectURL(file);
      })
      .then((pdf) => setState((prevState) => ({ ...prevState, antreten: pdf })))
      .catch((err) => console.error(err.message));
  };

  const handleClose = () => {
    setState({ isOpen: false, antreten: null });
  };

  const renderModal = () => (
    <GenericModal closeModal={handleClose} size="lg" isOpen={Boolean(isOpen && antreten)}>
      {antreten ? (
        <object data={antreten} type="application/pdf" width="100%" height="100%">
          Couldn&apos;t load ressource
        </object>
      ) : <CircularProgress color="secondary" />}
    </GenericModal>
  );

  return (
    <AppBar position="static" className={classes.banner}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            Treffpunkte
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              onClick={() => console.info('clicked')}
              color="inherit"
              aria-haspopup
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {briefe.map((brief) => (
              <Button
                key={brief}
                onClick={() => openPDF(brief)}
                sx={{
                  my: 2,
                  color: 'black',
                  display: 'block',
                  margin: 'auto',
                  fontSize: '16px',
                }}
              >
                {brief}
              </Button>
            ))}
          </Box>

          {
            renderModal()
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default AntretenBanner;
