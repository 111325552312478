import React, {
  useEffect, useState,
} from 'react';

import {
  Container,
} from '@mui/material';
// import { styled } from '@mui/material/styles';
import { ContainerProps } from './Models';

// const PREFIX = 'FadeInContainer';

// const classes = {
//   default: `${PREFIX}-default`,
//   fadeIn: `${PREFIX}-fadeIn`,
// };

// const StyledContainer = styled(Container)(() => ({
//   [`& .${classes.default}`]: {
//     opacity: 0,
//     transition: '300ms',
//     display: 'flex',
//   },
// }));

export default function FadeInContainer(props: ContainerProps): JSX.Element {
  const [firstRun, setFirstRun] = useState(true);

  const {
    trigger = true,
    children,
    flexDirection,
    style = {},
  } = props;

  const styles = {
    default: {
      opacity: 0,
      transition: '300ms',
      display: 'flex',
      maxWidth: 'none',
      flexDirection: flexDirection as React.CSSProperties || 'none',
    },
    fadeIn: {
      opacity: 100,
      transition: '300ms',
      display: 'flex',
      maxWidth: 'none',
      flexDirection: flexDirection as React.CSSProperties || 'none',
    },
  };

  useEffect(() => {
    setFirstRun(false);
  }, []);

  const defaultStyles = trigger && !firstRun
    ? styles.fadeIn as React.CSSProperties
    : styles.default as React.CSSProperties;

  return (
    <Container style={{
      ...defaultStyles,
      ...style,
    }}
    >
      {children}
    </Container>
  );
}
