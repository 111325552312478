import React, {
  // forwardRef,
  MouseEvent, useState,
} from 'react';
import {
  Button, Menu, MenuItem, Typography,
} from '@mui/material';

import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

interface SingleItem {
  name: string,
  url: string,
  strong?: boolean
}

interface CustomMenuModel {
  items: SingleItem[],
  menuName: string
}

const PREFIX = 'CustomMenu';

const classes = {
  button: `${PREFIX}-button`,
  menu: `${PREFIX}-menu`,
  menuItem: `${PREFIX}-menuItem`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.button}`]: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    fontSize: 'inherit',
  },
  [`& .${classes.menu}`]: {
    pointerEvent: 'none',
    elevation: 2,
  },
  [`& .${classes.menuItem}`]: {
    fontSize: '20px',
  },
}));

function CustomMenu(props: CustomMenuModel) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const { items, menuName } = props;

  // const MappedMenuItem = forwardRef(() => { // add forwardRef to pass down ref
  //   const Mapped = items.map((item) => (
  //     <MenuItem
  //       key={item.name}
  //       onClick={() => {
  //         setAnchor(null);
  //       }}
  //       component={Link}
  //       to={item.url}
  //       className={classes.menuItem}
  //     >
  //       <Typography style={item.strong ? { fontWeight: 600 } : {}} variant="body1">
  //         {item.name}
  //       </Typography>
  //     </MenuItem>
  //   ));

  //   return (
  //     <>{Mapped}</>
  //   );
  // });

  return (
    <Root>
      <Button
        className={classes.button}
        // TODO any Type
        onClick={
          (event: MouseEvent<HTMLElement>) => {
            setAnchor(event.currentTarget);
          }
        }
      >
        {menuName}
      </Button>
      <Menu
        elevation={0}
        open={Boolean(anchor)}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setAnchor(null)}
        className={classes.menu}
        MenuListProps={{
          onMouseLeave: () => setTimeout(() => setAnchor(null), 300),
        }}
        disableScrollLock
      >
        {
          items.map((item) => (
            <MenuItem
              key={item.name}
              onClick={() => {
                setAnchor(null);
              }}
              component={Link}
              to={item.url}
              className={classes.menuItem}
            >
              <Typography style={item.strong ? { fontWeight: 600 } : {}} variant="body1">
                {item.name}
              </Typography>
            </MenuItem>
          ))
        }
      </Menu>
    </Root>
  );
}

export default CustomMenu;
