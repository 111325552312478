import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { ModalProps } from './Models';

const sizes = {
  xs: {
    width: '20%',
    height: '40%',
  },
  sm: {
    width: '30%',
    height: '40%',
  },
  md: {
    width: '50%',
    height: '75%',
  },
  lg: {
    width: '80%',
    height: '90%',
  },
};

function GenericModal(props: ModalProps): JSX.Element {
  const {
    closeModal,
    children,
    size,
    isOpen,
  } = props;

  let style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '16px 16px 8px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    width: '',
    height: '',
    outline: 'none',
    '-ms-overflow-style': 'none', // IE 10+
    overflow: '-moz-scrollbars-none',
  };

  style = {
    ...style,
    ...sizes[size] || sizes.md,
  };

  if (!isOpen) return <div />;

  return (
    <Modal
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open
    >
      <Box
        sx={style}
        className="modal-body"
      >
        {children}
      </Box>
    </Modal>

  );
}

export default GenericModal;
