import React, { useState } from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { Fab, Drawer, Typography } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const PREFIX = 'SideNavigation';

const classes = {
  fabPos: `${PREFIX}-fabPos`,
  fab: `${PREFIX}-fab`,
  button: `${PREFIX}-button`,
  drawer: `${PREFIX}-drawer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.fabPos}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '8px',
    position: 'fixed',
    bottom: 35,
    right: 0,
    zIndex: 1000,
  },
  [`& .${classes.fab}`]: {
    backgroundColor: theme.palette.secondary.light,
  },
  [`& .${classes.button}`]: {
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'left',
  },
  [`& .${classes.drawer}`]: {
    height: '110%',
  },
}));

export default function SideDrawer() : JSX.Element {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    if (!openDrawer) {
      setOpenDrawer(true);
    } else {
      setOpenDrawer(false);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Root>
      <div className={classes.fabPos}>
        <Fab className={classes.fab} onClick={toggleDrawer}>
          <MenuIcon color="primary" />
        </Fab>
      </div>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer}
        disableScrollLock
        classes={{
          paper: classes.drawer,
        }}
        ModalProps={{ BackdropProps: { className: classes.drawer } }}
      >
        <List>
          <ListItem>
            <Button
              component={Link}
              to="/"
              className={classes.button}
              onClick={toggleDrawer}
            >
              Home

            </Button>
          </ListItem>

          <ListItem>
            <Button
              component={Link}
              to="/abteilung"
              className={classes.button}
              onClick={toggleDrawer}
            >
              Abteilung

            </Button>
          </ListItem>

          <ListItem>
            <Button
              component={Link}
              to={`/photos/${new Date().getFullYear()}`}
              className={classes.button}
              onClick={() => toggleDrawer()}
            >
              Photos
            </Button>
          </ListItem>

          <Divider />

          <ListItem>
            <List>
              <ListItem style={{ padding: '6px 8px' }}>
                <Link to="/stufen">
                  <Typography variant="h6" style={{ textDecoration: 'none', color: 'black' }}>Stufen</Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/stufen/woelfli"
                  className={classes.button}
                  onClick={toggleDrawer}
                >
                  Wölfli

                </Button>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/stufen/pfadi"
                  className={classes.button}
                  onClick={toggleDrawer}
                >
                  Pfadi

                </Button>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/stufen/pio"
                  className={classes.button}
                  onClick={toggleDrawer}
                >
                  PIO

                </Button>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/stufen/pta"
                  className={classes.button}
                  onClick={toggleDrawer}
                >
                  PTA

                </Button>
              </ListItem>
            </List>
          </ListItem>

          <Divider />

          <ListItem>
            <List>
              <ListItem style={{ padding: '6px 8px' }}>
                <Typography variant="h6">Infos</Typography>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/bekleidung"
                  className={classes.button}
                  onClick={toggleDrawer}
                >
                  Bekleidung

                </Button>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/pfadiheim"
                  className={classes.button}
                  onClick={toggleDrawer}
                >
                  Pfadiheim

                </Button>
              </ListItem>
            </List>
          </ListItem>

          <Divider />

          <ListItem>
            <Button
              component={Link}
              to="/apv"
              className={classes.button}
              onClick={toggleDrawer}
            >
              APV

            </Button>
          </ListItem>

          <Divider />

          <ListItem>
            <Button
              component={Link}
              to="/intern"
              className={classes.button}
              onClick={toggleDrawer}
            >
              Intern

            </Button>
          </ListItem>

          <ListItem>
            <Button
              component={Link}
              to="/links"
              className={classes.button}
              onClick={toggleDrawer}
            >
              Links

            </Button>
          </ListItem>

          <ListItem>
            <Button
              component={Link}
              to="/kontakt"
              className={classes.button}
              onClick={toggleDrawer}
            >
              Kontakt

            </Button>
          </ListItem>

          <ListItem style={{ height: '10%' }} />
        </List>
      </Drawer>
    </Root>
  );
}
