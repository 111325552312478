import React from 'react';
import { styled } from '@mui/material/styles';
import LockScroll from './LockScroll';
import { ImageViewerModel } from './Models';

const PREFIX = 'ImageViewer';

const classes = {
  image: `${PREFIX}-image`,
  imageHover: `${PREFIX}-imageHover`,
  bigImageContainerOpen: `${PREFIX}-bigImageContainerOpen`,
  bigImageContainerClosed: `${PREFIX}-bigImageContainerClosed`,
  bigImageOpen: `${PREFIX}-bigImageOpen`,
  bigImageClosed: `${PREFIX}-bigImageClosed`,
  backgroundOpen: `${PREFIX}-backgroundOpen`,
  backgroundClosed: `${PREFIX}-backgroundClosed`,
  fitContent: `${PREFIX}-fitContent`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.image}`]: {
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'relative',
    top: 0,
    transition: '0.6s',
    alignSelf: 'center',
  },
  [`& .${classes.imageHover}`]: {
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'relative',
    top: 0,
    transition: '0.4s',
    filter: 'brightness(0.5)',
    alignSelf: 'center',
  },
  [`& .${classes.bigImageContainerOpen}`]: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    bottom: 0,
    left: 0,
    opacity: 1,
    zIndex: 9997,
  },
  [`& .${classes.bigImageContainerClosed}`]: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  [`& .${classes.bigImageOpen}`]: {
    maxHeight: '90%',
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    transition: '0.6s',
    opacity: 1,
    zIndex: 9998,
  },
  [`& .${classes.bigImageClosed}`]: {
    width: 0,
    height: 0,
    transition: '0.6s',
    opacity: 0,
  },
  [`& .${classes.backgroundOpen}`]: {
    backgroundColor: '#00000080',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.6s',
    zIndex: 9998,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#000000',
      position: 'relative',
      height: '120%',
      top: '-10%',
    },
  },
  [`& .${classes.backgroundClosed}`]: {
    backgroundColor: '#00000000',
    width: '100%',
    height: '100%',
    transition: '0.6s',
  },
  [`& .${classes.fitContent}`]: {
    height: 'fit-content',
    maxHeight: '100%',
    width: 'fit-content',
    maxWidth: '100%',
  },
}));

export default function ImageViewer(props: ImageViewerModel): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const { image, alt } = props;

  function handleClick() {
    setOpen((prevState) => !prevState);
  }

  function eventListenerhandleEsc(event: globalThis.KeyboardEvent) {
    if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    LockScroll(open);

    document.addEventListener('keydown', (e) => eventListenerhandleEsc(e));
    return () => document.addEventListener('keydown', (e) => eventListenerhandleEsc(e));
  }, [open]);

  function handleEnter() {
    setHover(true);
  }

  function handleLeave() {
    setHover(false);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function onKeyUphandleEsc(e: KeyboardEvent<Element>) {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  }

  return (
    <Root className={classes.fitContent}>
      <div
        onClick={handleClick}
        onKeyUp={onKeyUphandleEsc}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        role="button"
        tabIndex={0}
      >
        <img
          src={image}
          alt={alt}
          className={hover ? classes.imageHover : classes.image}
        />
      </div>
      <div className={open ? classes.bigImageContainerOpen : classes.bigImageContainerClosed}>
        <div
          className={open ? classes.backgroundOpen : classes.backgroundClosed}
          onClick={() => handleClick()}
          onKeyUp={onKeyUphandleEsc}
          role="button"
          tabIndex={-1}
        >
          <img
            src={image}
            alt={alt}
            className={open ? classes.bigImageOpen : classes.bigImageClosed}
          />
        </div>
      </div>
    </Root>
  );
}
