import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import {
  Card, CardContent, CardHeader, CardMedia, Typography,
} from '@mui/material';
import { StufeModel, Stufen } from '../../utility/Models';

import ConfigContext from '../../context/ConfigContext';
import FadeInContainer from '../../utility/FadeInContainer';

function StufenPage(): JSX.Element {
  const { stufen } = useContext(ConfigContext);

  const styles = {
    stufenCard: {
      width: '25%',
    },
  };

  const getImg = (stufe: StufeModel) => (
    <Link to={stufe.route} className="stufen_link">
      <CardHeader
        title={stufe.title}
        subheader={stufe.stufe}
      />
      <CardMedia
        component="img"
        image={stufe.imgSrc}
        alt="fehler"
      />
    </Link>
  );

  const getText = (stufe: StufeModel) => (
    <Typography gutterBottom variant="body1" component="div">
      {stufe.text}
    </Typography>
  );

  const getStufen = () => {
    if (!stufen) return <CircularProgress color="secondary" />;

    return stufen.map((stufe: Stufen) => (
      <Card style={styles.stufenCard} key={stufe.title}>
        {getImg(stufe)}
        <CardContent>
          {getText(stufe)}
        </CardContent>
      </Card>
    ));
  };

  return (
    <FadeInContainer trigger={!!stufen}>
      {getStufen()}
    </FadeInContainer>
  );
}

export default StufenPage;
