import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { Dialog, IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';

import { PhotoSliderModel } from './Models';

const PREFIX = 'CustomMenu';

const classes = {
  imageContainer: `${PREFIX}-imageContainer`,
  stepper: `${PREFIX}-stepper`,
  stepperButton: `${PREFIX}-stepperButton`,
  downloadButton: `${PREFIX}-downloadButton`,
  closeButton: `${PREFIX}-closeButton`,
  closeButtonSvg: `${PREFIX}-closeButtonSvg`,
  dialog: `${PREFIX}-dialog`,
};

const RootDialog = styled(Dialog)({
  [`& .${classes.imageContainer}`]: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.stepper}`]: {
    backgroundColor: 'transparent',
    maxWidth: 300,
    margin: 'auto',
    color: '#fff',
  },
  [`& .${classes.stepperButton}`]: {
    color: '#fff',
  },
  [`& .${classes.downloadButton}`]: {
    color: 'white',
    width: 300,
    margin: 'auto',
  },
  [`& .${classes.closeButton}`]: {
    color: 'white',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '50px',
    width: '50px',
    zIndex: 1000,
  },
  [`& .${classes.closeButtonSvg}`]: {
    height: '50px',
    width: '50px',
  },
  [`&.${classes.dialog}`]: {
    backgroundColor: 'transparent',
    overflow: 'visible',
  },
});

function PhotoSlider(props: PhotoSliderModel): JSX.Element {
  const theme = useTheme();

  const {
    initialIndex,
    photos,
    isOpen,
    onClose,
    downloadCurrentImage,
  } = props;

  const [activeStep, setActiveStep] = useState(initialIndex);
  const amountOfPhotos = Object.keys(photos).length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const downloadImage = () => {
    if (downloadCurrentImage) {
      downloadCurrentImage();
    }
  };

  const getSlides = (): JSX.Element[] => {
    const allPhotos = Object.keys(photos);

    return allPhotos.map((imgName, index) => {
      const img = photos[imgName];
      return (
        <div key={img.name} className={classes.imageContainer} id={`imageContainer-${index}`}>
          <img src={img.data} height={window.innerHeight - 200} alt="Img not found" />
        </div>
      );
    });
  };

  if (photos && amountOfPhotos) {
    return (
      <RootDialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
        className={classes.dialog}
        maxWidth="lg"
      >
        <IconButton className={classes.closeButton} onClick={() => onClose()}>
          <Cancel className={classes.closeButtonSvg} />
        </IconButton>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {getSlides()}
        </SwipeableViews>
        <MobileStepper
          steps={amountOfPhotos}
          position="static"
          variant="text"
          activeStep={activeStep}
          className={classes.stepper}
          nextButton={(
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === amountOfPhotos - 1}
              className={classes.stepperButton}
            >
              Weiter
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          )}
          backButton={(
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              className={classes.stepperButton}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Zurück
            </Button>
          )}
        />
        {downloadCurrentImage
          && (
            <Button variant="text" onClick={downloadImage} className={classes.downloadButton}>
              Bild
              herunterladen
            </Button>
          )}
      </RootDialog>
    );
  }

  return (
    <Typography variant="body1">
      The photo slider could not be loaded.
    </Typography>
  );
}

PhotoSlider.defaultProps = {
  downloadCurrentImage: () => { console.error('no download function passed'); },
};

export default PhotoSlider;
