import React, { useContext, useState } from 'react';

import {
  Card, CardContent, CardMedia, Collapse, List, ListItem, Typography,
} from '@mui/material';
import FadeInContainer from '../utility/FadeInContainer';

import ConfigContext from '../context/ConfigContext';

import { Contact } from '../utility/Models';

function Team(): JSX.Element {
  const { team } = useContext(ConfigContext);

  const [
    active,
    setState,
  ] = useState<string>(
    'Abteilungsleitung',
  );

  const styles = {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    contacts: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
    },
  };

  const toggle = (newActiveState: string) => {
    setState(newActiveState);
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const img = e.target as HTMLImageElement;
    img.onerror = null;
    img.src = '/contacts/poeple_placeholder.png';
  };

  const getTeamContact = (con: Contact, size: number) => {
    let width = 0;

    if (size > 4) width = 24;
    else width = 100 / size - 1;

    return (
      <div
        style={{
          width: `${width}%`,
          display: 'flex',
          justifyContent: 'center',
          margin: '5px',
        }}
        key={con.img}
      >
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            image={`/contacts/${con.img}.jpg`}
            alt="error"
            onError={handleImageError}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {con.vollerName}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              <p>{con.leiterRolle}</p>
              {con.email ? (
                <p>
                  Email:
                  {' '}
                  {con.email}
                </p>
              ) : null}
              {con.handy ? (
                <p>
                  Handynummer:
                  {' '}
                  {con.handy}
                </p>
              ) : null}
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  };

  const getHeadings = () => {
    if (!team) {
      return <div />;
    }

    return team.map((stufe) => (
      <div key={stufe.stufe} style={styles.container as React.CSSProperties}>
        <ListItem button onClick={() => toggle(stufe.stufe)}>{stufe.stufe}</ListItem>
        <Collapse in={active === stufe.stufe}>
          <div style={styles.contacts as React.CSSProperties}>
            { stufe.contacts.map((con) => getTeamContact(con, stufe.contacts.length)) }
          </div>
        </Collapse>
      </div>
    ));
  };

  return (
    <FadeInContainer trigger={!!team} flexDirection="column">
      <List>
        {
          getHeadings()
        }
      </List>
    </FadeInContainer>
  );
}

export default Team;
