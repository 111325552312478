import { PdfFile, PhotoFile, PhotoMapping } from '../utility/Models';

export interface ParsedResult {
  img: PhotoFile[],
  pdf: PdfFile[],
  mov: string[]
}

export interface FetchedFile {
  type: 'img' | 'pdf' | 'video';
  data: PhotoFile | PdfFile | string;
  name?: string;
}

export interface DispatcherFunction {
  (state: PhotoMapping, file: FetchedFile): PhotoMapping;
}

interface UpdateDispatcher {
  (update: FetchedFile): void
}

let totalData = '';
let img: PhotoFile[] = [];
let pdf: PdfFile[] = [];
let mov: string[] = [];

const updateData = (stringChunk: string, dispatch: UpdateDispatcher, dir: boolean) => {
  totalData += stringChunk;

  if (totalData.includes('}')) {
    const allParts = totalData.split('}');
    const allImgObj = allParts.slice(0, allParts.length - 1);

    totalData = allParts[allParts.length - 1];

    allImgObj.forEach((obj) => {
      const fullObj = `${obj}}`;
      // console.info(fullObj);
      const {
        type,
        data,
        file,
      } = JSON.parse(fullObj);

      const currentImage = dir ? {
        name: file,
        data,
      } : data;

      switch (type.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif': { dispatch({ data: currentImage, type: 'img' }); break; }
        case 'pdf': { dispatch({ data: currentImage, name: file, type: 'pdf' }); break; }
        case 'avi':
        case 'vob':
        case 'mp4': { dispatch({ data: currentImage, type: 'video' }); break; }
        default: break;
      }
    });
  }

  // setState(stateUpdate);
};

const processData = (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  { done, value }: ReadableStreamDefaultReadResult<Uint8Array>,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reader: { read: () => Promise<ReadableStreamDefaultReadResult<Uint8Array>>; },
  dispatch: UpdateDispatcher,
  dir: boolean,
): Promise<ParsedResult> | ParsedResult => {
  if (done) {
    totalData = '';
    return {
      img,
      pdf,
      mov,
    };
  }

  if (value) {
    const currentChunk = new TextDecoder('utf-8').decode(value);

    updateData(currentChunk, dispatch, dir);
  }

  return reader.read()
    .then((data) => processData(data, reader, dispatch, dir));
};

const parser = (
  chunk: Response,
  dispatch: UpdateDispatcher,
  dir = false,
): Promise<ParsedResult> | ParsedResult => {
  totalData = '';
  img = [];
  pdf = [];
  mov = [];

  if (!chunk || !chunk.body) {
    return {
      img,
      pdf,
      mov,
    };
  }

  const reader = chunk.body.getReader();

  return reader.read().then((data) => processData(data, reader, dispatch, dir));
};

export default parser;
