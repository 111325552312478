import React, { useContext } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import ConfigContext from '../context/ConfigContext';
import ImageViewer from '../utility/ImageViewer';
import FadeInContainer from '../utility/FadeInContainer';

function Home(): JSX.Element {
  const { staticUrls } = useContext(ConfigContext);

  const styles = {
    main: {
      display: 'flex',
      width: '100%',
      marginTop: '15px',
    },
    image: {
      width: '50%',
    },
    text: {
      width: '50%',
      margin: '10px',
    },
  };

  return (
    <FadeInContainer trigger={!!staticUrls}>
      <div className="margin-top" style={styles.main}>
        <div className="abteilung_front" style={styles.image}>
          {
            staticUrls
              ? <ImageViewer alt="abteilung" image={staticUrls.abteilung.front} />
              : <CircularProgress color="secondary" />
          }
        </div>
        <div className="abteilung_text" style={styles.text}>
          <h1>Pfadi Bischostein</h1>
          <div>
            <p>
              Wir sind Pfadi Bischofstein, ein kleiner aber
              stolzer Pfadi Verein in Basel und Umgebung.
              Unseren Verein gibt es schon schon seit 1940.
            </p>
            <p>
              <b>Abteilungsruf:</b>
              <br />
              <b>
                Ziratscha, ziratscha, ziratschua
                / Sawiwo, sawiwo, sawakura
                / Birembo, birembo, birei - Bischofstai!
              </b>
            </p>
          </div>
        </div>
      </div>
    </FadeInContainer>
  );
}

export default Home;
