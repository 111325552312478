import React, { useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  List, ListItem, ListItemButton, ListItemText,
} from '@mui/material';
import ConfigContext from '../context/ConfigContext';
import FadeInContainer from '../utility/FadeInContainer';
import { LinkList } from '../utility/Models';

function Links(): JSX.Element {
  const { links } = useContext(ConfigContext);

  const styles = {
    col: {
      width: '33%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  };

  const getLinks = () => {
    if (!links) return <CircularProgress color="secondary" />;

    const chunkedLinks = [];
    for (let i = 0; i < links.length; i += 3) {
      chunkedLinks.push(links.slice(i, i + 3));
    }

    return chunkedLinks.map((chunk) => (
      <div style={styles.row as React.CSSProperties} key={`chunk_${chunk[0].category}`}>
        {
          chunk.map((category: LinkList) => (
            <div style={styles.col} key={category.category}>
              <h1 className="no-margin-bottom sectionHeader">{category.category}</h1>
              <List>
                {
                  category.links.map((link) => (
                    <ListItem component="a" href={link.link} target="_blanc" key={link.caption}>
                      <ListItemButton>
                        <ListItemText primary={link.caption} />
                        {link.icon
                          ? (
                            <span>
                              <img
                                className="linkIcon"
                                src={`/link/${link.icon}`}
                                alt="Something weng wrong"
                              />
                            </span>
                          ) : null}
                      </ListItemButton>

                    </ListItem>
                  ))
                }
              </List>
            </div>
          ))
        }
      </div>
    ));
  };

  return (
    <FadeInContainer trigger={!!links} flexDirection="column">
      { getLinks() }
    </FadeInContainer>
  );
}

export default Links;
