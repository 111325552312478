import React, { useState, useContext } from 'react';

import ConfigContext from '../../context/ConfigContext';

import StufenTemplate from './StufenTemplate';

function Pfadi():JSX.Element {
  const { staticUrls } = useContext(ConfigContext);

  const [{ isOpen }, setState] = useState<{ isOpen: boolean, antreten: string | null }>({
    isOpen: false,
    antreten: null,
  });

  const toggle = () => {
    const link = `/getGenericPdf/${staticUrls?.pfadi.antreten}`;

    setState((prevState) => ({ ...prevState, isOpen: !isOpen }));

    fetch(link)
      .then((data) => data.blob())
      .then((data) => {
        const file = new Blob([data], {
          type: 'application/pdf',
        });
        return URL.createObjectURL(file);
      })
      .then((pdf) => setState((prevState) => ({ ...prevState, antreten: pdf })))
      .catch((err) => console.error(err.message));
  };

  const buttons = [{
    label: 'Blinddate',
    type: 'pdf',
    fetcher: (): Promise<string> => {
      const link = `/getGenericPdf/${staticUrls?.pfadi.antreten}`;

      return fetch(link)
        .then((data) => data.blob())
        .then((data) => {
          const file = new Blob([data], {
            type: 'application/pdf',
          });
          return URL.createObjectURL(file);
        });
    },
  }];

  const contact = (
    <div>
      Tobias Wahl / Chip
      <br />
      Email: chip@bischofstein.ch
    </div>
  );

  return (
    <StufenTemplate
      isLoading={!!staticUrls}
      buttons={buttons}
      title="Pfadistamm Gutenfels"
      titleImage={staticUrls?.pfadi.front}
      contact={contact}
    >
      <p>
        Mädchen und Buben von 10-14 Jahren gehören zu den Pfadi.
        Geleitet werden sie von älteren, erfahrenen Jugendlichen.
        Ihre Aktivitäten finden an den Samstagnachmittagen statt.
        Treffpunkt ist jeweils an einer Tram- oder Bushaltestelle in der Region.
        <br />
        <br />
        Nebst viel Spiel und Spass werden den Pfadi praktische Kenntnisse
        über das Leben in der Natur, 1. Hilfe, Kartenlesen,
        Kochen etc. vermittelt. Wissen, welches sie im zweiwöchigen
        Zelt-Sommerlager benötigen. Die einzelnen Pfadi lernen
        dabei Verantwortung zu übernehmen, und als Gruppe Ziele zu erreichen.
        Die Selbständigkeit wird stark gefördert.
        <br />
        <br />
        Über Pfingsten fährt jeweils die ganze Abteilung in das Pfingstlager.
        Die Pfadi gehen in den ersten zwei Sommerferienwochen in ein Zeltlager.
        <br />
        <br />
        Wo wir uns in der nächsten Zeit treffen, kannst du dem
        <span
          className="link-to-doc"
          onClick={toggle}
          onKeyUp={toggle}
          role="button"
          tabIndex={0}
        >
          Blinddate

        </span>
        {' '}
        entnehmen.
        <br />
        <br />
        Wir freuen uns auf deinen Besuch!
        {' '}
        <br />
        <br />
        Weitere Informationen findest du im
        {' '}
        <a href="http:stein.ch/inhalt/stufen/pfadiprofil.pdf" target="_blanc">Pfadiprofil</a>
        {' '}
        der Pfadibewegung Schweiz!
      </p>
    </StufenTemplate>
  );
}

export default Pfadi;
