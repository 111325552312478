import React, { SyntheticEvent } from 'react';

import { useHistory } from 'react-router-dom';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { BreadcrumbModel } from './Models';

function CustomBreadcrumb(props: BreadcrumbModel): JSX.Element {
  const {
    year,
    event,
    category,
  } = props;

  const history = useHistory();

  const handleClick = (e: SyntheticEvent, link: string) => {
    e.preventDefault();

    history.push(link);
  };

  const renderYearCrumb = () => {
    const link = `/photos/${year}`;

    return (
      <Button color="inherit" onClick={event ? (e: SyntheticEvent) => handleClick(e, link) : () => null}>
        {year}
      </Button>
    );
  };

  const renderEventCrumb = () => {
    if (!event) return null;

    const link = `/photos/${year}/${event}`;

    return (
      <Button color="inherit" onClick={category ? (e:SyntheticEvent) => handleClick(e, link) : () => null}>
        {event}
      </Button>
    );
  };

  const renderCategoryCrumb = () => (category ? <Typography color="textPrimary">{category}</Typography> : null);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      { renderYearCrumb() }
      { renderEventCrumb() }
      { renderCategoryCrumb() }
    </Breadcrumbs>
  );
}

export default CustomBreadcrumb;
