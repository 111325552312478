import React, { useContext } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { TableBody, TableCell, TableRow } from '@mui/material';
import FadeInContainer from '../utility/FadeInContainer';
import ConfigContext from '../context/ConfigContext';

function Kontakt(): JSX.Element {
  const { verteiler } = useContext(ConfigContext);

  const styles = {
    marginTop: {
      marginTop: '15px',
    },
  };

  const getVerteilerTable = (category: 'allgemein' | 'stuche') => {
    if (!verteiler) return <CircularProgress color="secondary" />;

    return verteiler[category].map(({ address, empfaenger, beschreibung }) => (
      <TableRow key={empfaenger}>
        <TableCell>{address}</TableCell>
        <TableCell>{empfaenger}</TableCell>
        <TableCell>{beschreibung}</TableCell>
      </TableRow>
    ));
  };

  return (
    <FadeInContainer trigger={!!verteiler} flexDirection="column">
      <div style={styles.marginTop}>
        <h3>Allgemeine Verteiler</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Verteiler-Adresse</TableCell>
              <TableCell>Empfänger</TableCell>
              <TableCell>Beschreibung</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { getVerteilerTable('allgemein') }
          </TableBody>
        </Table>
      </div>
      <div style={styles.marginTop}>
        <h3>Stufen-Verantwortung</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Verteiler-Adresse</TableCell>
              <TableCell>Empfänger</TableCell>
              <TableCell>Beschreibung</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { getVerteilerTable('stuche') }
          </TableBody>
        </Table>
      </div>
    </FadeInContainer>
  );
}

export default Kontakt;
