import React, { useEffect, useState, useCallback } from 'react';

import EventCard from './EventCard';
import EventPhotos from './EventPhotos';

interface LoadingCompProps {
  year: string,
  event: string,
  category: string
}

function LoadingComp(props : LoadingCompProps): JSX.Element {
  const {
    year,
    event,
    category,
  } = props;

  const [{
    dirMode,
    link,
    files,
  }, setState] = useState({
    dirMode: undefined,
    link: '',
    files: [],
  });

  const getPhotoLink = useCallback(() => {
    let fullLink = year;

    if (event) fullLink += `/${event}`;
    if (category) fullLink += `/${category}`;

    return fullLink;
  }, [year, event, category]);

  const getPath = () => {
    let fullLink = year;

    if (event) fullLink = event;
    if (category) fullLink = category;

    return fullLink;
  };

  useEffect(() => {
    let isMounted = true;
    const photoLink = getPhotoLink();
    const dirCheckLink = `/checkDir/${photoLink}`;

    fetch(dirCheckLink)
      .then((data) => data.json())
      .then(({ dir, files: dirFiles }) => {
        const currentLink = dir ? `/getPhotoDir/${photoLink}` : `/getPhotos/${photoLink}`;

        if (isMounted) {
          setState((prevState) => ({
            ...prevState,
            dirMode: dir,
            link: currentLink,
            files: dirFiles,
          }));
        }
      });

    return () => {
      isMounted = false;
    };
  }, [getPhotoLink]);

  if (dirMode === undefined) return <div>loading</div>;
  return dirMode
    ? <EventCard link={link} path={getPath()} files={files} />
    : <EventPhotos link={link} path={getPath()} files={files} />;
}

export default LoadingComp;
