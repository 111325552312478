import React, { useState } from 'react';
import {
  Button,
  Card, CardActionArea, CardActions, CardContent, Typography,
} from '@mui/material';

import Modal from './GenericModal';

import ImageLoader from './ImageLoader';
import { NewsProps } from './Models';

interface NewsBlogState {
  modal: boolean,
  pdf: string | null,
}

function NewsBlog(props: NewsProps): JSX.Element {
  const [{
    modal,
    pdf,
  }, setState] = useState<NewsBlogState>({
    modal: false,
    pdf: null,
  });

  const {
    linkedDoc,
    imgSrc,
    title,
    date,
    text,
    style,
  } = props;

  const toggle = () => {
    const link = `/getGenericPdf/${linkedDoc}`;

    fetch(link)
      .then((data) => data.blob())
      .then((data) => {
        const file = new Blob([data], {
          type: 'application/pdf',
        });

        const fileURL = URL.createObjectURL(file);
        return fileURL;
      })
      .then((pdfFile) => setState((prevState) => ({ ...prevState, pdf: pdfFile })));

    setState((prevState) => ({
      ...prevState,
      modal: !modal,
    }));
  };

  let cardComp = (
    <>
      <ImageLoader height="100%" imgName={imgSrc} publicImg />
      <CardContent>
        <Typography gutterBottom variant="h4" component="div">
          {title}
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          {date}
        </Typography>
        <Typography variant="body1" component="div">
          {text}
        </Typography>
      </CardContent>
    </>
  );

  if (linkedDoc) {
    cardComp = (
      <CardActionArea onClick={toggle}>
        {cardComp}
        <CardActions disableSpacing>
          <Button color="secondary">Mehr Info</Button>
        </CardActions>
      </CardActionArea>
    );
  }

  return (
    <div className="newsBlog" style={style}>
      <Card>
        {cardComp}
      </Card>
      {
        linkedDoc
          ? (
            <Modal isOpen={modal} closeModal={toggle} size="lg">
              {
                pdf
                  ? (
                    <object data={pdf} type="application/pdf" height="100%" width="100%">
                      Error loading
                    </object>
                  )
                  : 'loading...'
              }
            </Modal>
          ) : null
      }
    </div>
  );
}

export default NewsBlog;
