import React from 'react';

import { Link } from 'react-router-dom';
import FadeInContainer from '../utility/FadeInContainer';

function Impressum(): JSX.Element {
  const styles = {
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      width: '15%',
    },
    container: {
      fontSize: '20px',
    },
  };

  return (
    <FadeInContainer trigger flexDirection="column" style={styles.container}>
      <h1 className="no-margin-bottom sectionHeader">Vereinsangaben</h1>
      <div>
        <div style={styles.row as React.CSSProperties}>
          <div style={styles.label}>Name:</div>
          <p>Pfadi Bischofstein</p>
        </div>
        <div style={styles.row as React.CSSProperties}>
          <div style={styles.label}>Sitz:</div>
          <p>
            Pfadiheim bei St. Jakob in der Nähe, siehe
            {' '}
            <Link to="/pfadiheim">Pfadiheim</Link>
          </p>
        </div>
        <div style={styles.row as React.CSSProperties}>
          <div style={styles.label}>Kontakt:</div>
          <p>lego@bischofstein.ch / hulk@bischofstein.ch</p>
        </div>
      </div>
      <br />
      <div>
        <h1 className="no-margin-bottom sectionHeader">Webseite</h1>
      </div>
      <div>
        <div>
          <div style={styles.row as React.CSSProperties}>
            <div style={styles.label}>Autor:</div>
            <p>Tobias Wahl / Chip</p>
          </div>
          <div style={styles.row as React.CSSProperties}>
            <div style={styles.label}>Kontakt:</div>
            <p>chip@bischofstein.ch</p>
          </div>
        </div>
      </div>
    </FadeInContainer>
  );
}

export default Impressum;
