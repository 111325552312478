import React, { useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from '@mui/styles';
import NewsBlog from '../utility/newsBlog';

import ConfigContext from '../context/ConfigContext';

import { News } from '../utility/Models';
import FadeInContainer from '../utility/FadeInContainer';

import AntretenBanner from '../utility/AntretenBanner';

const useStyles = makeStyles({
  parent: {
    position: 'relative',
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
  backdrop: {
    position: 'absolute',
    width: 'inherit',
    height: 'inherit',
  },
});

function Home(): JSX.Element {
  const { homeNews } = useContext(ConfigContext);

  const classes = useStyles();

  const styles = {
    newsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    newsRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    newsBlock: {
      width: '33%',
    },
  };

  const getNewsBlogs = () => {
    const chunkedNews = [];

    if (!homeNews) {
      return null;
    }

    for (let i = 0; i < homeNews.length; i += 3) {
      const singleNewsChunk = homeNews.slice(i, i + 3);
      chunkedNews.push(singleNewsChunk);
    }

    return chunkedNews.map((firstThree) => (
      <div
        key={firstThree.reduce((final, news) => final + news.title, '')}
        style={styles.newsRow as React.CSSProperties}
      >
        {
          firstThree.map((news: News) => (
            <NewsBlog
              title={news.title}
              imgSrc={news.imgSrc}
              text={news.text}
              date={news.date}
              linkedDoc={news.linkedDoc}
              style={styles.newsBlock as React.CSSProperties}
              key={news.title}
            />
          ))
        }
      </div>
    ));
  };

  return (
    <FadeInContainer trigger={!!homeNews} flexDirection="column">
      <AntretenBanner />
      {homeNews
        ? (
          <div style={styles.newsContainer as React.CSSProperties}>
            {getNewsBlogs()}
          </div>
        )
        : (
          <div className={classes.parent}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              className={classes.backdrop}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
    </FadeInContainer>
  );
}

export default Home;
