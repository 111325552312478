import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    // eslint-disable-next-line max-len
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  palette: {
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#2b2b2b',
      contrastText: '#181818',

    },
    secondary: {
      light: '#26963c',
      main: '#124363',
      dark: '#001d39',
      contrastText: '#ffffff',
    },
    background: {
      default: '#fffefc',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 900,
      lg: 1330,
      xl: 1920,
    },
  },
});

export default theme;
