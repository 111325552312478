import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ConfigContext from '../context/ConfigContext';
import { Info } from '../utility/Models';
import FadeInContainer from '../utility/FadeInContainer';

function InfoPage(): JSX.Element {
  const { info } = useContext(ConfigContext);

  const styles = {
    half: {
      width: '48%',
    },
    container: {
      justifyContent: 'space-between',
    },
  };

  const getSection = () => {
    if (!info) return <CircularProgress color="secondary" />;

    return info.map((section: Info) => (
      <div key={section.title} style={styles.half} className="stufen_header">
        <Link to={section.route} className="info_link">
          <h3>{section.title}</h3>
          <h6>{section.text}</h6>
          <img src={section.imgSrc} alt="Fehler beim Laden vom Bild..." width="100%" />
        </Link>
      </div>
    ));
  };

  return (
    <FadeInContainer trigger={!!info} style={styles.container}>
      {getSection()}
    </FadeInContainer>
  );
}

export default InfoPage;
