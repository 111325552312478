import React, { useContext } from 'react';

import ConfigContext from '../../context/ConfigContext';
import StufenTemplate from './StufenTemplate';

function Pio():JSX.Element {
  const { staticUrls } = useContext(ConfigContext);

  const buttons = [{
    label: 'Blinddate',
    type: 'pdf',
    disabled: true,
    fetcher: (): Promise<string> => {
      const link = `/getGenericPdf/${staticUrls?.pta.antreten}`;

      return fetch(link)
        .then((data) => data.blob())
        .then((data) => {
          const file = new Blob([data], {
            type: 'application/pdf',
          });
          return URL.createObjectURL(file);
        });
    },
  }];

  return (
    <StufenTemplate
      isLoading={!!staticUrls}
      buttons={buttons}
      title="Piostamm Thierstein"
      titleImage={staticUrls?.pio.front}
    >
      <p>
        <div className="red-border">
          <b>Aktuelle Situation</b>
          <br />
          Die meisten unserer ehemaligen Pionniers sind inzwischen bei
          den Wölfen und Pfadi als Leiter/innen tätig oder können aus sonstigen
          Gründen nicht mehr an den Aktivitäten teilhaben. Daher ist der Betrieb der
          Pio-Stufe vorläufig eingestellt.
          <br />
          <b>Ausblick</b>
          <br />
          Unser Ziel ist es, wieder eine aktive Pio-Stufe zu führen und
          altergerechte Aktivitäten durchzuführen. Wir freuen uns daher
          über jegliches Interesse von 14-17jährigen an unserer Pfadiabteilung.
          Noch jede/r hat bei uns (s)einen Platz gefunden!
          <br />
        </div>
        Mädchen und Buben von 14-17 Jahren gehören zu den Pionniers, kurz Pios genannt.
        Sie haben in der Pfadi schon viel erlebt und gelernt und nehmen nun
        die Gestaltung ihres Programms selbst in die Hand: Sie überlegen,
        was sie tun wollen, was es dazu benötigt - und wie sie ihre Aktivität
        realisieren können. Dabei werden sie von erfahrenen Leitern/innen betreut,
        die ihnen mit Rat und Tat zur Seite stehen.
        Den Aktivitäten sind grundsätzlich keine Grenzen gesetzt, sofern sie
        Pfadi-Charakter haben und die Sicherheit aller Teilnehmer/innen gewährleistet ist.
        Ihre Aktivitäten reichen vom gemütlichen Beisammensein, über einen Kinobesuch,
        eine Bergwanderung mit Hütten- oder Biwak-Übernachtung, einer
        Schneeschuhtour bis zur Teilnahme an einem Seifenkistenrennen.
        Zeitpunkte und Orte der Zusammentreffen der Pionniers
        ergeben sich jeweils aus den geplanten Aktivitäten.
        <br />
        <br />
        Über Pfingsten fährt jeweils die ganze Abteilung in das Pfingstlager.
        Die Pios gehen in der Regel zusammen mit den Pfadi in den ersten
        zwei Sommerferienwochen in ein Zeltlager.
        <br />
        <br />
        Wir freuen uns auf deinen Besuch!
        <br />
        <br />
        Weitere Informationen findest du im
        <a href="http://bischofstein.ch/inhalt/stufen/pfadiprofil.pdf" target="_blanc">Pfadiprofil</a>
        der Pfadibewegung Schweiz!
      </p>
    </StufenTemplate>
  );
}

export default Pio;
