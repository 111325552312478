import React, { ChangeEvent, useState, useContext } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';

import shajs from 'sha.js';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '../utility/GenericModal';
import { InternCatModel, InternStateModel } from '../utility/Models';

import ConfigContext from '../context/ConfigContext';
import FadeInContainer from '../utility/FadeInContainer';

function Intern(): JSX.Element {
  const [{
    loggedIn,
    loginAttempt,
    pw,
    isOpen,
    modalData,
    filetype,
    files,
  }, setState] = useState<InternStateModel>({
    loggedIn: false,
    loginAttempt: true,
    pw: '',
    isOpen: false,
    modalData: null,
    filetype: '',
    files: [],
  });

  const styles = {
    row: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    col: {
      width: '23%',
    },
    notLoggedInContainer: {
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      marginTop: '15px',
    },
    logInButton: {
      width: '15%',
      height: '50px',
      border: '1px grey solid',
      borderRadius: '4px',
      color: 'black',
      marginTop: '5px',
      backgroundColor: '#eeeeee',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const { fileNamesMapping } = useContext(ConfigContext);

  const fetchInterMetadata = () => {
    fetch('/getInternMetaData')
      .then((data) => data.json())
      .then((data) => setState((prevState) => ({
        ...prevState,
        files: [...data, {
          name: 'papiere',
          files: [
            'bi-Papier.doc',
            'Buchhaltungspapier.doc',
            'dorneck-papier.doc',
            'gutenfels-papier.doc',
            'seeonee-papier.doc',
            'thierstein-papier',
          ],
        }],
      })));
  };

  const checkPw = () => {
    const pwHash = '9b238d532901b8abc7787c2f067a2853f230e08d6711e8f6f7f66eb2981426a2';
    const hash = shajs('sha256').update(pw).digest('hex');

    if (hash === pwHash) {
      setState((prevState) => ({ ...prevState, loginAttempt: true, loggedIn: true }));
      fetchInterMetadata();
    } else {
      setState((prevState) => ({ ...prevState, loginAttempt: true, loggedIn: false }));
    }
  };

  const toggle = (name: string, folder?: string, currentFileType?: string) => {
    setState((prevState) => ({ ...prevState, isOpen: !isOpen, modalData: 'loading file...' }));

    if (isOpen) return;

    const link = `/getInternFile/${name}/${folder}`;

    fetch(link)
      .then((data) => (currentFileType === 'pdf'
        ? data.blob()
        : data.json().then(({ data: imgData }) => imgData)))
      .then((data) => setState((prevState) => ({
        ...prevState,
        modalData: data,
        filetype: currentFileType || '',
      })))
      .catch((err) => {
        setState((prevState) => ({ ...prevState, modalData: err.message }));
      });
  };

  const keyHandler = (key: string, toggleFunc: () => void) => {
    if (key === 'Enter') { toggleFunc(); }
  };

  const getMappedName = (name: string) => {
    if (!fileNamesMapping) return name;

    return fileNamesMapping[name];
  };

  // TODO why subtitle and subTitle?
  const getList = (cat: InternCatModel) => (
    <div key={cat.name} className="sectionContainer">
      <div style={styles.list as React.CSSProperties}>
        <h1 className="no-margin-bottom sectionHeader">
          {
            getMappedName(cat.name)
          }
        </h1>
      </div>
      <div style={styles.list as React.CSSProperties}>
        <List>
          {
            cat.files.map((fileName) => {
              const parts = fileName.split('.');
              const currentFileType = parts[parts.length - 1];
              return (
                <ListItem key={`${cat.name}${fileName}`}>
                  {
                    currentFileType === ('doc' || 'docx')
                      ? <a href={`/papiere/${fileName}`} download>{fileName}</a>
                      : (
                        <div>
                          <div
                            className="link-to-doc break-word"
                            onClick={() => toggle(fileName, cat.name, currentFileType)}
                            onKeyUp={(e) => keyHandler(
                              e.code,
                              () => toggle(fileName, cat.name, currentFileType),
                            )}
                            role="button"
                            tabIndex={0}
                          >
                            {fileName}

                          </div>
                        </div>
                      )
                  }
                </ListItem>
              );
            })
          }
        </List>
      </div>
    </div>
  );

  const getInput = (event: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, pw: event.target.value }));
  };

  window.addEventListener('keydown', ((event) => {
    setTimeout(() => {
      if (event.key === 'Enter') {
        checkPw();
      }
    }, 50);
  }));

  const notLoggedIn = () => (
    <div className="margin-top" style={styles.notLoggedInContainer as React.CSSProperties}>
      <FormGroup>
        <FormLabel>Password</FormLabel>
        <Input type="password" name="password" onChange={getInput} value={pw} />
      </FormGroup>
      <Button onClick={checkPw} style={styles.logInButton} color="primary">Einloggen</Button>
      {
        !loginAttempt ? <b className="wrongPW">Falsches Passwort</b> : null
      }
    </div>
  );

  const getModalBody = (data: string | null, currentFileType?: string) => {
    if (!data || !currentFileType) return <div />;

    switch (currentFileType) {
      case 'jpg': {
        return <img src={data} height="100%" alt="problem here" />;
      }
      case 'pdf': {
        const file = new Blob([data], {
          type: 'application/pdf',
        });

        const pdfData = URL.createObjectURL(file);
        return (
          <object data={pdfData} type="application/pdf" width="100%" height="100%">
            Couldn&apos;t load ressource
          </object>
        );
      }
      default: {
        return <div>Problem here</div>;
      }
    }
  };

  const loggedInContent = () => {
    if (!files || !files.length) return <CircularProgress color="secondary" />;

    const chunkedLinks = [];
    for (let i = 0; i < files.length; i += 4) {
      chunkedLinks.push(files.slice(i, i + 4));
    }

    return chunkedLinks.map((chunk) => (
      <div key={chunk[0].name} style={styles.row}>
        <div style={styles.col} className="internSection">{ getList(chunk[0]) }</div>
        {
          chunk[1] ? <div style={styles.col} className="internSection">{ getList(chunk[1]) }</div> : null
        }
        {
          chunk[2] ? <div style={styles.col} className="internSection">{ getList(chunk[2]) }</div> : null
        }
        {
          chunk[3] ? <div style={styles.col} className="internSection">{ getList(chunk[3]) }</div> : null
        }
      </div>
    ));
  };

  return (
    <FadeInContainer
      trigger={!!files}
      flexDirection="column"
    >
      {
        loggedIn ? loggedInContent() : notLoggedIn()
      }
      <Modal isOpen={isOpen} closeModal={() => toggle('')} size="lg">
        {getModalBody(modalData, filetype)}
      </Modal>
    </FadeInContainer>
  );
}

export default Intern;
