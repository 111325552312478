import React, { useState } from 'react';

import { Button, CircularProgress } from '@mui/material';
import FadeInContainer from '../../utility/FadeInContainer';
import ImageViewer from '../../utility/ImageViewer';
import GenericModal from '../../utility/GenericModal';

type ButtonFunction = () => Promise<string>;

interface ButtonType {
  label: string,
  source?: string,
  fetcher?: ButtonFunction,
  type?: string,
  disabled?: boolean,
}

interface StufenTemplateProps {
  isLoading: boolean,
  buttons: ButtonType[],
  title: string,
  titleImage: string | undefined,
  children: JSX.Element,
  contact?: JSX.Element | undefined,
}

interface ModalState {
  isOpen: boolean,
  currentSource: string | undefined,
  type: string,
}

function StufenTemplate(props: StufenTemplateProps) {
  const {
    isLoading,
    buttons,
    title,
    titleImage = '',
    children,
    contact = <p />,
  } = props;

  const [{ isOpen, currentSource, type }, setState] = useState<ModalState>({
    isOpen: false,
    currentSource: undefined,
    type: 'image',
  });
  const [fetching, setFetching] = useState<boolean>(true);

  const styles = {
    titleBanner: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      width: '100%',
    },
    titleBannerInfo: {
      display: 'flex',
      padding: '10px',
    },
    buttonSection: {
      width: '60%',
    },
    contactSection: {
      width: '40%',
      display: 'flex',
      flexDirection: 'row',
    },
    titleImage: {
      width: '50%',
    },
    description: {
      width: '50%',
      padding: '15px',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
    },
  };

  const openModal = (source?: string, newType?: string) => {
    if (!source) return;

    setFetching(false);
    setState((prevState) => ({
      ...prevState, isOpen: true, currentSource: source, type: newType || 'image',
    }));
  };

  const clickHandler = ({ fetcher, source, type: newType }: ButtonType): void => {
    if (fetcher) {
      fetcher()
        .then((data) => openModal(data, newType));

      return;
    }

    openModal(source, newType);
  };

  const createButton = (button: ButtonType) => (
    <Button
      className="line-height link-to-doc"
      onClick={() => clickHandler(button)}
      onKeyDown={() => clickHandler(button)}
      disabled={button.disabled}
      color="secondary"
    >
      {button.label}
    </Button>
  );

  const stufenBanner = (
    <div style={styles.titleBanner as React.CSSProperties}>
      <div style={styles.title}>
        <h1 className="no-margin-bottom">{title}</h1>
      </div>
      <div className="stufenInfo margin-bottom" style={styles.titleBannerInfo}>
        <div style={styles.buttonSection}>
          {buttons.map((button) => createButton(button))}
        </div>
        <div style={styles.contactSection as React.CSSProperties}>
          {contact}
        </div>
      </div>
    </div>
  );

  const content = (
    <div className="margin-top" style={styles.content as React.CSSProperties}>
      <div className="abteilung_front" style={styles.titleImage}>
        {
          isLoading
            ? <ImageViewer alt="abteilung" image={titleImage} />
            : <CircularProgress color="secondary" />
        }
      </div>
      <div className="abteilung_text" style={styles.description}>
        {children}
      </div>
    </div>
  );

  const renderContent = () => {
    switch (type || 'image') {
      case 'image': return <img className="margin-center" src={currentSource} alt="Problem here" />;
      case 'pdf': return (
        <object data={currentSource} type="application/pdf" width="100%" height="100%">
          Couldn&apos;t load ressource
        </object>
      );
      default: return <p>Error</p>;
    }
  };

  return (
    <FadeInContainer trigger={isLoading}>
      <div className="margin-left">
        {stufenBanner}
        {content}
        <GenericModal closeModal={() => setState({ isOpen: false, currentSource: undefined, type: 'image' })} isOpen={isOpen} size="md">
          {
            !fetching
              ? renderContent()
              : <CircularProgress color="secondary" />
          }
        </GenericModal>
      </div>
    </FadeInContainer>
  );
}

StufenTemplate.defaultProps = {
  contact: undefined,
};

export default StufenTemplate;
