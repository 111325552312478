import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import React, {
  useEffect,
  useReducer,
} from 'react';
import { useHistory } from 'react-router-dom';

import imgParserPipeline, { FetchedFile } from '../helper-functions/image-parser';

import { PhotoFile, PhotoMapping } from './Models';

interface EventCardProps {
  link: string,
  path: string,
  files: string[],
}

const init = () => ({});

const reducer = (state: PhotoMapping, update: FetchedFile) => {
  const file = update.data as PhotoFile;

  return {
    ...state,
    [file.name]: file,
  };
};

function EventCard(props : EventCardProps): JSX.Element {
  const [photos, dispatch] = useReducer<(state: PhotoMapping, file: FetchedFile) => PhotoMapping,
  PhotoMapping
  >(reducer, {}, init);

  const history = useHistory();

  const {
    link,
    path,
    files,
  } = props;

  const styles = {
    row: {
      display: 'flex',
    },
    card: {
      margin: '5px',
    },
  };

  useEffect(() => {
    fetch(link, {
      // eslint-disable-next-line
      // @ts-ignore
      'Transfer-Encoding': 'chunked',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((data) => imgParserPipeline(
        data,
        dispatch,
        true,
      ))
      .catch((err) => {
        console.error(err);
      });
  }, [link, dispatch]);

  const getPhotosDisplay = () => {
    const chunkedEvents = [];

    for (let i = 0; i < files.length; i += 3) {
      chunkedEvents.push(files.slice(i, i + 3));
    }

    return chunkedEvents.map((singleChunk) => (
      <div key={singleChunk[0]} className="margin-bottom" style={styles.row}>
        {
          singleChunk.map((eventName) => {
            const event = photos[eventName];
            if (!event) return null;

            return (
              <div style={styles.card} key={event.name}>
                <Card>
                  <CardActionArea onClick={() => history.push(`${path}/${event.name}`)}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={event.data}
                      alt="error"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {event.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            );
          })
        }
      </div>
    ));
  };

  return (
    <div>
      { getPhotosDisplay() }
    </div>
  );
}

export default EventCard;
