import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { CardMedia, CircularProgress } from '@mui/material';

interface ImageLoaderProps {
  height: string,
  imgName?: string,
  publicImg?: boolean,
  styleClass?: string
}

const PREFIX = 'ImageLoader';

const classes = {
  spinner: `${PREFIX}-spinner`,
};

function ImageLoader(props: ImageLoaderProps): JSX.Element {
  const {
    imgName,
    height,
    publicImg,
    styleClass,
  } = props;

  const [contactImg, setImg] = useState<string | undefined>();

  const Root = styled('div')(({ theme }) => ({
    [`& .${classes.spinner}`]: {
      margin: 'auto',
      position: 'relative',
      width: '100%',
      height,
      top: '45%',
      left: '45%',
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

  useEffect(() => {
    if (publicImg) {
      setImg(imgName);
      return;
    }

    fetch(`/getContactImg/${imgName}`)
      .then((res) => res.json())
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        }

        const imgData = data || '/img/poeple_placeholder.png';

        setImg(imgData);
      });
  }, [imgName, publicImg]);

  return (
    <Root>
      {contactImg === null
        ? (
          <div className={classes.spinner}>
            <CircularProgress color="secondary" />
          </div>
        )
        : (
          <CardMedia
            component="img"
            className={styleClass}
            height="250"
            image={contactImg}
            alt="error"
          />
        )}
    </Root>
  );
}

ImageLoader.defaultProps = {
  styleClass: undefined,
  imgName: null,
  publicImg: false,
};

export default ImageLoader;
